enum Primary {
    Primary100 = '#FFFFFF',
    Primary200 = '#BABBBF',
    Primary300 = '#727272',
    Primary400 = '#646464',
}

enum Secondary {
    Secondary100 = '#565656',
    Secondary200 = '#484848',
    Secondary300 = '#404040',
    Secondary400 = '#323232',
}

enum Tertiary {
    Tertiary100 = '#242424',
    Tertiary200 = '#161616',
    Tertiary300 = '#080808',
    Tertiary400 = '#000000',
}

enum Positive {
    Positive100 = '#5FBF79',
    Positive200 = '#273A2D',
    Positive300 = '#122016',
}

enum Negative {
    Negative100 = '#DB3236',
    Negative200 = '#491816',
    Negative300 = '#280E0F',
}

enum Neutral {
    Neutral100 = '#F6BF4F',
    Neutral200 = '#4E432D',
    Neutral300 = '#3A2F1B',
}

enum Accent {
    Accent100 = '#FED4D4',
}

export const Base = {
    ...Primary,
    ...Secondary,
    ...Tertiary,
    ...Positive,
    ...Negative,
    ...Neutral,
    ...Accent,
};

export type Base = typeof Base;
