import { Typography, Breakpoints, Common, Colors, Palette } from '@internal/ui/legacy/styles';
import { createSemanticColorFactory, defaultSemanticPalette } from '@internal/ui/palette/semantic';
import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    typography: Typography,
    breakpoints: Breakpoints,
    common: Common,
    colors: Colors,
    palette: Palette,
    semanticColor: createSemanticColorFactory(defaultSemanticPalette),
};
