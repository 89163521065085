import { css } from 'styled-components';
import { Base } from './base';

/**
 * The default palette defines all of the required keys in a `SemanticPalette`.
 *
 * Naming convention: {category}-{variant}-{target}--{state}
 */
export const defaultSemanticPalette = {
    // Action Primary
    'action-primary-bg--disabled': Base.Primary400,
    'action-primary-bg--hover': Base.Primary200,
    'action-primary-bg--pressed': Base.Primary200,
    'action-primary-bg': Base.Primary100,
    'action-primary-fg--disabled': Base.Secondary400,
    'action-primary-fg--hover': Base.Tertiary300,
    'action-primary-fg--pressed': Base.Tertiary300,
    'action-primary-fg': Base.Tertiary300,

    // Action Secondary
    'action-secondary-bg--disabled': Base.Secondary400,
    'action-secondary-bg--hover': Base.Tertiary200,
    'action-secondary-bg--pressed': Base.Tertiary200,
    'action-secondary-bg': Base.Secondary300,
    'action-secondary-fg--disabled': Base.Primary300,
    'action-secondary-fg--hover': Base.Primary100,
    'action-secondary-fg--pressed': Base.Primary100,
    'action-secondary-fg': Base.Primary100,
};

export type SemanticPalette = { [key in keyof typeof defaultSemanticPalette]: string };

/**
 * A color factory function provides a type-safe way to dynamically access values in the palette.
 * This is only necessary while typescript strict mode is disabled. When enabled, dynamic property
 * access on objects will result in a type error when an unknown field is provided.
 *
 * For example (strict mode disabled):
 *  - semanticColor('unknown'): type error
 *  - semanticColors['unknown']: no type error
 */
export type SemanticColorFactory = (key: keyof SemanticPalette) => string;

export const createSemanticColorFactory: (palette: SemanticPalette) => SemanticColorFactory =
    (palette) => (key) => {
        return palette[key];
    };
